/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, Link } from "gatsby"
import { SkipNavTarget } from "../../components/shared/components/SkipNav"
import Container from "../../components/container"
import Header from "../../components/header"
import Footer from "../../components/footer"
import HeroContainer from "../../components/containers/hero"
import Layout from "../../layouts"
import Title from "../../components/hero/title"
import Subtitle from "../../components/hero/subtitle"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import PageMetadata from "../../components/page-metadata"
import { MdArrowForward as ArrowForwardIcon } from "react-icons/md"
import SVGInline from "react-svg-inline"
import colorBlocks from "!raw-loader!svgo-loader!../../assets/color-blocks.svg"
import handDroppingBall from "!raw-loader!svgo-loader!../../assets/hand-dropping-ball.svg"
import ballBeingRolled from "!raw-loader!svgo-loader!../../assets/ball-being-rolled.svg"

const SVGImage = ({ svg, file }) =>
  svg && svg.content && file.contentType === "image/svg+xml" ? (
    <span
      sx={{ svg: { height: 20 } }}
      dangerouslySetInnerHTML={{ __html: svg.content }}
    />
  ) : null

const styles = {
  axisGrid: {
    marginTop: `2rem`,
    display: `grid`,
    gridGap: theme => `${theme.space[7]} ${theme.space[9]}`,
    gridTemplateColumns: [`1fr`, `1fr`, `1fr`, `repeat(2, 1fr)`],
  },
  axisHeading: {
    paddingBottom: `1rem`,
    color: `purple.30`,
    fontSize: `100%`,
    textTransform: `uppercase`,
  },
  landingPageLink: {
    textDecoration: `none`,
    display: `block`,
    transition: theme => theme.transitions.default,
    marginBottom: `1rem`,
    ":hover": {
      marginLeft: `0.5rem`,
    },
  },
  landingPageLinkText: {
    color: `white`,
    fontSize: 5,
    marginLeft: `0.5rem`,
    lineHeight: `heading`,
    fontFamily: `heading`,
    fontWeight: `bold`,
  },
}

const UseCasesArt = () => (
  <div
    sx={{
      display: [`none`, null, null, null, `initial`],
    }}
  >
    <div
      sx={{
        position: `absolute`,
        right: 25,
      }}
    >
      <SVGInline svg={handDroppingBall} />
    </div>
    <div
      sx={{
        position: `absolute`,
        right: 325,
        top: 250,
      }}
    >
      <SVGInline svg={ballBeingRolled} />
    </div>
    <div
      sx={{
        position: `absolute`,
        right: 0,
        top: 0,
      }}
    >
      <SVGInline svg={colorBlocks} />
    </div>
  </div>
)

const UseCaseAxis = ({ axis }) => (
  <section key={axis.id}>
    <h2 sx={styles.axisHeading} id={axis.slug}>
      {axis.displayTitle ? axis.displayTitle : axis.title}
    </h2>
    <ul sx={{ marginLeft: 0, listStyle: `none` }}>
      {axis.contentfulchildren.map((page, i) => (
        <li key={i}>
          <Link
            to={`/use-cases/` + page.slug}
            key={page.id}
            sx={styles.landingPageLink}
          >
            {page.landingPageIcon ? (
              <SVGImage
                svg={page.landingPageIcon.svg}
                fluid={page.landingPageIcon.fluid}
                file={page.landingPageIcon.file}
              />
            ) : null}
            <span sx={styles.landingPageLinkText}> {page.title}</span>
            <ArrowForwardIcon
              sx={{ display: `inline`, marginLeft: `0.5rem` }}
            />
          </Link>
        </li>
      ))}
    </ul>
  </section>
)

const UseCasePage = ({ data: { allContentfulUseCaseHomepage } }) => {
  const homepage = allContentfulUseCaseHomepage.nodes[0]

  return (
    <Layout background={theme => theme.colors.purple[90]}>
      <UseCasesArt />
      <PageMetadata
        title={homepage.metaTitle || "Use Cases"}
        description={
          homepage.metaDescription ||
          documentToPlainTextString(
            homepage.description?.raw
              ? JSON.parse(homepage.description.raw)
              : null
          )
        }
        image={{
          contentfulSrc: homepage.socialImage?.file?.url,
          width: 1600,
          height: 836,
        }}
        twitterCard={`summary_large_image`}
      />
      <Header isInverted={true} />
      <main>
        <SkipNavTarget />
        <HeroContainer isInverted>
          <Container>
            <Title isInverted>{homepage.title}</Title>
            <Subtitle isInverted css={{ maxWidth: 650 }}>
              {renderRichText(homepage.description ?? {})}
            </Subtitle>
          </Container>
          <Container css={{ position: `static` }}>
            <div sx={styles.axisGrid}>
              {homepage.axes.map((axis, i) => (
                <UseCaseAxis axis={axis} key={i} />
              ))}
            </div>
          </Container>
        </HeroContainer>
      </main>
      <Footer isInverted />
    </Layout>
  )
}

export default UseCasePage

export const pageQuery = graphql`
  query UseCaseQuery {
    allContentfulUseCaseHomepage {
      nodes {
        title
        description {
          raw
        }
        metaTitle
        metaDescription
        socialImage {
          file {
            url
          }
        }
        axes {
          id
          title
          displayTitle
          slug
          contentfulchildren {
            id
            slug
            title
            landingPageIcon {
              svg {
                content # SVG content optimized with SVGO
                dataURI # Optimized SVG as compact dataURI
                absolutePath #
                relativePath #
              }
              file {
                contentType
                url
                fileName
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
